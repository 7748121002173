import { initializeApp } from 'firebase/app'

export const app = initializeApp({
  apiKey: 'AIzaSyD9bmmPSXbSXkTkOxuVFvW59Tk9VVVuYfc',

  authDomain: 'auth.pulfy.com',

  projectId: 'pulfy-2021',

  storageBucket: 'pulfy-2021.appspot.com',

  messagingSenderId: '1006259222509',

  appId: '1:1006259222509:web:a81dda60c475e61653d9e4',

  measurementId: 'G-3HJW1EZ2QJ'

})

